<template>
  <div>
    <div class="container locations pb-8 fadeInFast">
      <HeadlineTableView
          :icon="'mdi mdi-map-marker-radius-outline'"
          :headline="'Locations'"
          :button-icon="'mdi mdi-map-marker-radius-outline'"
          :button-text="'Neue Location'"
          :entity="'location'"
          @buttonClick="openDialogCreateLocation()"
      ></HeadlineTableView>

      <Message
          :message="message"
          @closeAlert="message.show = false"
      ></Message>

      <v-card flat class="rounded-xl pa-2 pa-sm-4">
        <v-row class="align-center">
          <v-col cols="12" md="6">
            <Tab
                :tabs="['Liste', 'Karte', 'Archiv']"
                :register="register"
                @changed="register=$event"
            >
            </Tab>
          </v-col>
          <v-col cols="12" md="2">
            <a v-if="!showFilter" @click="showFilter=true">Filter
              <v-icon>mdi-arrow-down</v-icon>
            </a>
            <a v-if="showFilter" @click="showFilter=false; activeFilters = []; filter()">Filter leeren
              <v-icon>mdi-arrow-up</v-icon>
            </a>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field type="text" v-model="searchText" label="Suche Location..."
                          class="me-6 searchInput" @keyup="search"/>
          </v-col>
        </v-row>

        <v-row v-if="showFilter" class="pb-6 ps-lg-6 pe-lg-6 justify-content-center" style="z-index: 999">

          <v-col cols="12" sm="6" lg="3">
            <MultipleAutoComplete
                :data="{items:[{text: '€',value: 1},{text: '€€',value: 2},{text: '€€€',value: 3}], title:'Preisklasse', row: 'priceClass'}"
                @sendData="setFilter"
            ></MultipleAutoComplete>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <MultipleAutoComplete
                :data="{items:stil, title:'Stil', row: 'stil'}"
                @sendData="setFilter"
            ></MultipleAutoComplete>
          </v-col>
          <v-col cols="12" sm="4" lg="2">
            <TextField
                :data="{title:'Mind. Personen', row: 'maxPersonen'}"
                @sendData="setFilter"
                :type="'number'"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="2">
            <TextField
                :data="{title:'Mind. m² Innen', row: 'groesseInnen'}"
                @sendData="setFilter"
                :type="'number'"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="2">
            <TextField
                :data="{title:'Mind. m² Außen', row: 'groesseAussen'}"
                @sendData="setFilter"
                :type="'number'"
            />
          </v-col>

        </v-row>

        <div v-if="loaded">
          <TableView
              v-if="register === 0"
              :headLines="headLines"
              :rows="locationsSearched"
              :archiveEnabled="true"
              @archiveEntries="archiveLocations"
              @deleteEntries="deleteLocations"
              @openDetail="openDetailLocation"
              :textNoResults="'Hier werden deine Locations angezeigt.'"
              :entity="'location'"
          ></TableView>
          <div
              v-if="register === 1"
          >
            <Map
                :markers="locationsSearched"
            ></Map>
          </div>
          <TableView
              v-if="register === 2"
              :headLines="headLines"
              :rows="locationsSearchedArchived"
              @deleteEntries="deleteLocations"
              @openDetail="openDetailLocation"
              :textNoResults="'Hier werden deine archivierten Locations angezeigt.'"
              :entity="'location'"
          ></TableView>
        </div>
        <Spinner v-else></Spinner>
        <CreateLocation
            :dialog="dialog"
            :openAfterCreate="true"
            @hideDialog="hideDialog"
        ></CreateLocation>
      </v-card>
    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const TableView = () => import("@/components/tableView/TableView");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const CreateLocation = () => import("@/components/dialog/createDialogs/CreateLocation");
const Spinner = () => import("@/components/generalUI/Spinner");
const Map = () => import("@/components/generalUI/Map");
const MultipleAutoComplete = () => import("@/components/dialog/fields/MultipleAutoComplete");
const TextField = () => import("@/components/dialog/fields/TextField");
const Tab = () => import("@/components/generalUI/Tab");
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'Locations',
  components: {
    TableView,
    Message,
    HeadlineTableView,
    CreateLocation,
    Spinner,
    Map,
    MultipleAutoComplete,
    TextField,
    Tab
  },
  computed: {
    ...mapGetters('locations', {
      locations: 'locations',
      locationsArchived: 'locationsArchived',
      locationsSearched: 'locationsSearched',
      locationsSearchedArchived: 'locationsSearchedArchived'
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      stil: 'stilNames',
    }),
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      loadedTour: false,
      showFilter: false,
      register: 0,
      message: msgObj(),
      searchText: '',
      activeFilters: [],
      headLines: [
        {text: "Name", sort: 'name'},
        {text: "Stil", sort: 'stil'},
        {text: "Preisklasse", sort: 'priceClass'},
        {text: "Max. Personen", sort: 'maxPersonen'},
        {text: "Größe Innen", sort: 'groesseInnen'},
        {text: "Größe Außen", sort: 'groesseAussen'},
      ]
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('locations/getLocations', {
        uid: res
      }).then(() => {
        this.loaded = true
        this.$store.dispatch('settings/getStatusForSelection', {
          uid: this.user.id
        }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  methods: {
    openDialogCreateLocation() {
      this.dialog = true
    },
    openDetailLocation(id) {
      this.$router.push("locations/location/" + id)
    },
    hideDialog() {
      this.dialog = false
    },
    search() {
      if(this.register === 2){
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.locationsArchived}
        ).then((results)=>{
          this.$store.dispatch('locations/setLocationsSearchedArchived', results)
        })
      }else{
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.locations}
        ).then((results)=>{
          this.$store.dispatch('locations/setLocationsSearched', results)
        })
      }
    },
    setFilter(payload) {
      if (payload.value.length < 1) {
        delete this.activeFilters[payload.row]
      } else {
        this.activeFilters[payload.row] = payload.value
      }
      this.filter()
    },
    filter() {
      if (this.register === 2) {
        this.$store.dispatch('sort/filter', {activeFilters: this.activeFilters, list: this.locationsArchived}
        ).then((results) => {
          this.$store.dispatch('locations/setLocationsSearchedArchived', results)
        })
      }else{
        this.$store.dispatch('sort/filter', {activeFilters: this.activeFilters, list: this.locations}
        ).then((results) => {
          this.$store.dispatch('locations/setLocationsSearched', results)
        })
      }
    },
    async deleteLocations(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('locations/deleteLocation', {
          uid: this.user.id,
          locationId: id,
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            if (ids.length === 1) {
              this.message = success('Eine Location erfolgreich gelöscht.')
            } else {
              this.message = success(ids.length + " Locations erfolgreich gelöscht.")
            }
            setTimeout(() => this.message.show = false, 5000);
          }
        })
        await delay(100)
        i++
      }
    },
    async archiveLocations(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('customer/editField', {
          uid: this.user.id,
          row: 'isArchived',
          newValue: true,
          id,
          entity: 'locations'
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.$store.dispatch('locations/getLocations', {
              uid: this.user.id
            }).then(() => {
              this.loaded = true
              if (ids.length === 1) {
                this.message = success("Locations erfolgreich archiviert.")
              } else {
                this.message = success(ids.length + " Locations erfolgreich archiviert.")
              }
            }).catch((err) => {
              this.message = error(err)
            })
          }
        })
        await delay(100)
        i++
      }
    },
  }
}
</script>
